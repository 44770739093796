import React, {Component, ReactNode} from "react";
import ModalBase from "./ModalBase";
import RejectProposalModal from "./RejectProposalModal";
import FullPageLoadingIndicator from "../FullPageLoadingIndicator";

interface OwnProps
{
    show : boolean;
    handleClose : () => void;
    handleConfirm : () => void;
    rejectLakeOrProposal ?: (proposalState: number, proposalId: string, reason: string, rejectionType: string) => void;
    handleEdit? : () => void;
    proposalId? : string;
    pdfDocument: any;
    proposalTitle: string;
    isQuote? : boolean;
    isListing?: boolean;
}

type Props = OwnProps;

class ProposalModal extends Component<Props>
{
    public readonly state = {
        viewed: false,
        showRejectProposalModal: false,
        loading: false,
    };

    public render() : ReactNode
    {
        if (this.state.loading) {
            return <FullPageLoadingIndicator/>;
        }

        const {show, handleClose, handleConfirm, pdfDocument, proposalTitle, isQuote, rejectLakeOrProposal, proposalId, isListing} = this.props;
        const handleReject = rejectLakeOrProposal ? () => this.setState({showRejectProposalModal: true}) : undefined;
        let confirmText = isQuote ? 'Sign Quote' : 'Sign Proposal';

        if (isListing) {
            confirmText = isQuote ? 'Continue Quote' : 'Continue Proposal';
        }

        return (
            <React.Fragment>
                <ModalBase
                    show={show}
                    handleClose={handleClose}
                    handleConfirm={handleConfirm}
                    handleReject={handleReject}
                    title={isQuote ? 'Quote' : 'Proposal'}
                    confirmText={confirmText}
                    refForm="proposalForm"
                    size="lg"
                    btnClass="smallModalBtn"
                    pdfDocument={pdfDocument}
                    proposalTitle={proposalTitle}
                    isQuote={isQuote}
                    hideConfirmButton={true}
                >
                    {this.props.children}
                    {proposalId && !isQuote ? <p className="mb-0 mt-2 reviewText">You'll be able to add Optional Treatments/Services on the next screen.</p> : <React.Fragment/>}
                </ModalBase>
                <RejectProposalModal
                    show={this.state.showRejectProposalModal}
                    handleClose={() => {
                        this.setState({showRejectProposalModal: false});
                        handleClose();
                    }}
                    handleConfirm={(reason: string) => this.handleConfirmRejectProposalModal(reason)}
                    proposalTitle={proposalTitle}
                    loading={this.state.loading}
                />
            </React.Fragment>
        );
    }

    private async handleConfirmRejectProposalModal(reason: string) {
        if (this.props.rejectLakeOrProposal && this.props.proposalId) {
            this.setState({loading: true, showRejectProposalModal: false});
            await this.props.rejectLakeOrProposal(700, this.props.proposalId, reason, 'proposal');
            this.props.handleClose();
            this.setState({loading: false});
        }
    }
}

export default (ProposalModal);