import React, {Component, ReactNode} from 'react';
import {Route, Switch} from 'react-router-dom';
import Portal from './pages/Portal';
import NotFound404 from './pages/NotFound404'

class App extends Component
{
    public render() : ReactNode
    {
        return (
            <React.Fragment>
                <Switch>
                    <Route path="/contact/:contactId" exact component={Portal}/>
                    <Route path="*" component={NotFound404}/>
                </Switch>
            </React.Fragment>
        );
    }
}

export default App;
