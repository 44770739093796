import React, {Component, ReactNode} from "react";
import AddAdditionalLakeForm from "../forms/AddAdditionalLakeForm";
import {Coordinates} from "../../redux/contact/types";
import ModalBase from "./ModalBase";

interface OwnProps
{
    show : boolean;
    handleClose : () => void;
    handleConfirm : (lakeName : string, coordinates: Coordinates) => Promise<void>;
    coordinates: Coordinates;
}

type Props = OwnProps;

class AdditionalLakeModal extends Component<Props>
{
    public render() : ReactNode
    {
        const {show, handleConfirm, handleClose, coordinates} = this.props;

        return (
            <ModalBase
                show={show}
                handleClose={handleClose}
                title="Add Lake to Property"
                confirmText="Confirm"
                refForm="addAdditionalLakeForm"
                size="sm"
                btnClass="smallModalBtn"
            >
                <AddAdditionalLakeForm handleSubmit={handleConfirm} coordinates={coordinates} />
            </ModalBase>
        );
    }
}

export default (AdditionalLakeModal);