import React, {Component, ReactNode} from "react";
import {Alert, Button, Collapse} from "react-bootstrap";

interface OwnProps
{
    show : boolean;
    alertText : string | null;
    timeout? : number;
    variant? : any;
}

type Props = OwnProps;

class SystemAlert extends Component<Props>
{
    public render() : ReactNode
    {
        const {show, timeout, alertText, variant} = this.props;

        return (
            <Collapse in={show} timeout={timeout ? timeout : 1000}>
                <div className="fixed-top">
                    <Alert variant={variant ? variant : 'success'} className="rounded-0">
                        <Button type="button" className="close btn-success" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </Button>
                        <span className="justify-content-center d-flex">
                            {alertText}
                        </span>
                    </Alert>
                </div>
            </Collapse>
        );
    }
}

export default (SystemAlert);