import React, {Component, ReactNode} from 'react';
import NavBase from "../components/navigation/NavBase";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default class extends Component {
    public render(): ReactNode {
        return (
            <React.Fragment>
                <NavBase />
                <Container>
                    <Row>
                        <Col md={{span: 6, offset: 3}}>
                            <div className="d-flex justify-content-center align-items-center w-100 flex-column mt-5">
                                <h1>Page Not Found</h1>
                                <p>Uh oh, we can't seem to find the page you're looking for. Try going back to the
                                    previous page or contact support@aquaticcontrol.com assistance.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        );
    }
}
