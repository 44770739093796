import React, {ChangeEvent, Component, ReactNode} from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import {FormikErrors} from 'formik';

export interface RadioOption {
    label: string;
    value: string;
}


interface OwnProps
{
    name : string;
    type : string;
    value : string;
    handleChange : (e : ChangeEvent<any>) => void;
    label? : any;
    errors? : string | FormikErrors<any> | undefined;
    md? : number;
    lg? : number;
    hrBefore? : boolean;
    className? : string;
    placeholder? : string;
    disabled? : boolean;
    checked? : boolean;
    radioOptions?: Array<RadioOption>;
    min? : string;
    max? : string;
}

type Props = OwnProps;

class FormGroup extends Component<Props>
{
    public render() : ReactNode
    {
        const {
            label,
            name,
            type,
            value,
            handleChange,
            errors,
            lg,
            hrBefore,
            className,
            placeholder,
            disabled,
            checked,
            radioOptions,
            min,
            max
        } = this.props;

        return (
            <React.Fragment>
                {hrBefore && (<hr className={`${className} mr-4 ml-4`} />)}
                <Form.Group as={Col} lg={lg} controlId={`validation${name}`} className={className}>
                    {(type === 'text' || type === 'date' || type === 'time') && (
                        <React.Fragment>
                            <Form.Label column={false}>{label}</Form.Label>
                            <Form.Control
                                type={type}
                                name={name}
                                value={value}
                                onChange={handleChange}
                                isInvalid={!!errors}
                                className="border"
                                placeholder={placeholder}
                                disabled={disabled}
                                min={min}
                                max={max}
                            />
                        </React.Fragment>
                    )}
                    {type === 'textarea' && (
                        <React.Fragment>
                            <Form.Label column={false}>{label}</Form.Label>
                            <Form.Control
                                as={type}
                                rows={3}
                                name={name}
                                value={value}
                                onChange={handleChange}
                                isInvalid={!!errors}
                                placeholder={placeholder}
                            />
                        </React.Fragment>
                    )}
                    {type === 'checkbox' && (
                        <Form.Check
                            type={type}
                            name={name}
                            label={label}
                            value={value}
                            onChange={handleChange}
                            isInvalid={!!errors}
                            checked={checked}
                        />
                    )}
                    {type === 'radio' && radioOptions && (
                        <React.Fragment>
                            {radioOptions.map((radioOption : RadioOption, index) => (
                                <React.Fragment key={index}>
                                    <Form.Check
                                        id={'radio_' + name + index}
                                        type={type}
                                        name={name}
                                        label={radioOption.label}
                                        value={radioOption.value}
                                        onChange={handleChange}
                                        isInvalid={!!errors}
                                        checked={radioOption && radioOption.value === value}
                                    />
                                    {index+1 < radioOptions.length && (
                                        <p className="pt-1 pl-4 pb-1 mb-0"><strong>OR</strong></p>
                                    )}
                                </React.Fragment>
                            ))}
                        </React.Fragment>
                    )}
                    <Form.Control.Feedback type="invalid" className={className}>
                        {errors}
                    </Form.Control.Feedback>
                </Form.Group>
            </React.Fragment>
        );
    }
}

export default (FormGroup);