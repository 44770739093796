import React, {Component, ReactNode} from "react";
import ModalBase from "./ModalBase";
import SignatureCanvas from 'react-signature-canvas';
import PrintedNameForm from "../forms/PrintedNameForm";
import BottomScrollListener from "react-bottom-scroll-listener";

interface OwnProps
{
    show : boolean;
    handleClose : () => void;
    handleConfirm : (signature: string, printedName: string) => Promise<void>;
    isQuote? : boolean;
    company?: string;
    terms?: string;
}

interface State {
    trimmedDataURL: string | null;
    signatureError: boolean;
    viewed: boolean;
    termsError: boolean;
}

type Props = OwnProps;

class DigitalSignatureModal extends Component<Props, State>
{
    public readonly state = {
        trimmedDataURL: null,
        signatureError: false,
        viewed: false,
        termsError: false
    };

    public sigPad : any;

    public render() : ReactNode
    {
        const {show, handleClose, isQuote, terms, company} = this.props;

        return (
            <ModalBase
                show={show}
                handleClose={handleClose}
                title="Add Digital Signature"
                confirmText="Confirm"
                refForm="printedNameForm"
                size="md"
                btnClass="smallModalBtn"
            >
                <p>Use the signature pad below to add a digital signature to your {isQuote ? 'quote' : 'proposal'}</p>
                <div className="d-flex flex-row justify-content-center mb-3">
                    <SignatureCanvas
                        penColor='black'
                        canvasProps={{width: 400, height: 200, className: 'sigCanvas'}}
                        ref={(ref) => { this.sigPad = ref }}
                    />
                </div>
                <div className="d-flex flex-row pl-4 pr-4 justify-content-end">
                    {this.state.signatureError && (

                        <p className="text-danger w-75">You must add a digital signature.</p>
                    )}
                    <div className="text-primary cursor-pointer">
                        <p onClick={this.clearSignature}>Clear Signature</p>
                    </div>
                </div>
                <p>
                    Click and hold your left mouse button to complete your digital signature in the box above.
                    Release the mouse button when you have completed your signature.
                </p>
                <PrintedNameForm handleSubmit={this.handleConfirm}/>
                <h2 className="text-primary">Terms & Conditions</h2>
                <div className="scroll-box" >
                    {terms ? terms : (
                        <>
                            <p>This contract is for the complete program as described in the above specifications and options
                                sections, with material cost prorated over the entire contract period. Payment will be according
                                to the payment option chosen on back. Overdue accounts shall accrue interest at an annual interest
                                rate of 18%. Aquatic Control shall be entitled to collect its attorney fees incurred in the
                                collection of any balance due here under.</p>
                            <p>By signing below, you hereby agree and accept the above
                                prices, specifications, conditions, and terms and authorize Aquatic Control to do the work as
                                specified. Additionally, the undersigned warrants that the undersigned is authorized to sign and
                                accept this proposal on behalf of {company} for software testing, and will indemnify and hold
                                Aquatic Control harmless from third party claims for trespass, nuisance or any other challenge
                                to Aquatic Control's authority to perform the work provided for herein.</p>
                        </>
                    )}
                </div>
                <div className="d-flex flex-row justify-content-end mt-3 mr-1">
                    <h3 className="text-primary">Agree</h3><input className="ml-2" type="checkbox" onClick={() => { this.setState({viewed: !this.state.viewed})}} />
                </div>
                {this.state.termsError && (
                    <p className="text-danger w-100 mt-2 pb-0 mb-0">
                        You must "Agree" to the Terms & Conditions by clicking the checkbox above.
                    </p>
                )}
            </ModalBase>
        );
    }

    private handleConfirm = async (printedName: string): Promise<void> => {
        if (this.sigPad.isEmpty()) {
            this.setState({signatureError: true});
            return;
        }

        this.setState({signatureError: false});

        if (!this.state.viewed) {
            this.setState({termsError: true});
            return;
        }

        this.setState({termsError: false});

        const {handleConfirm} = this.props;
        let signature = this.sigPad.getTrimmedCanvas().toDataURL('image/png');

        await handleConfirm(signature, printedName);
    };

    private clearSignature = (): void => {
        this.sigPad.clear();
    }
}

export default (DigitalSignatureModal);