import React, {Component, ReactNode} from 'react';
import {Button, Modal, Spinner} from 'react-bootstrap';
import {saveAs} from "file-saver";

interface OwnProps
{
    show : boolean;
    handleClose : () => void | null;
    title : string | null;
    handleConfirm? : () => void;
    handleReject? : () => void;
    cancelText? : string;
    confirmText? : string;
    size? : any
    loading? : boolean;
    hideCloseButton? : boolean;
    refForm? : string;
    hideConfirmButton? : boolean;
    btnClass?: string;
    modalClass?: string;
    pdfDocument?: any;
    proposalTitle?: string;
    noPadding?: boolean;
    isQuote? : boolean;
}

type Props = OwnProps;

class ModalBase extends Component<Props>
{
    public render() : ReactNode
    {
        const {
            title,
            children,
            handleClose,
            handleConfirm,
            handleReject,
            show,
            cancelText,
            confirmText,
            size,
            loading,
            hideCloseButton,
            refForm,
            hideConfirmButton,
            btnClass,
            modalClass,
            pdfDocument,
            proposalTitle,
            noPadding,
            isQuote
        } = this.props;

        return (
            <Modal size={size ? size : 'lg'} show={show} onHide={handleClose} className={modalClass}>
                <Modal.Header translate="" closeButton className="border-0">
                    {title && (<h3 className="text-primary">{title}</h3>)}
                </Modal.Header>
                <Modal.Body className={noPadding ? 'p-0' : ''}>{children}</Modal.Body>
                <Modal.Footer className="border-0">
                    <div className="d-flex justify-content-end flex-column-reverse flex-sm-row">
                    {!hideCloseButton && (
                        <Button
                            variant="secondary"
                            onClick={handleClose}
                            className={btnClass ?
                                btnClass + ' w-auto mr-3 mb-3 mb-sm-0' : 'w-auto mr-3 mb-3 mb-sm-0'
                            }
                        >
                            {cancelText ? cancelText : 'Close'}
                        </Button>
                    )}
                    {pdfDocument && (
                        <Button className="w-auto mr-3 mb-3 mb-sm-0" onClick={() => {
                            // @ts-ignore
                            saveAs(pdfDocument, proposalTitle);
                        }}>Download {isQuote ? 'Quote' : 'Proposal'}</Button>
                    )}
                    {!hideConfirmButton && (
                        <Button
                            variant="primary"
                            onClick={handleConfirm}
                            form={refForm}
                            type="submit"
                            className={btnClass ?
                                btnClass + ' w-auto mr-3 mb-3 mb-sm-0' : 'w-auto mr-3 mb-3 mb-sm-0'
                            }
                        >
                            {(loading ?
                                <React.Fragment>
                                    <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true"/>
                                    &nbsp; Loading...
                                </React.Fragment>
                            :
                                <React.Fragment>{confirmText ? confirmText : 'Confirm'}</React.Fragment>
                            )}
                        </Button>
                    )}
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default (ModalBase);