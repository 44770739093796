import React, {Component, ReactNode} from 'react';
import {Formik} from 'formik';
import * as yup from 'yup';
import Form from "react-bootstrap/Form";
import {Payment, PaymentProfiles} from "../../redux/contact/types";
import FormGroup from "./FormGroup";
import moment from "moment";

interface OwnProps
{
    payment: Payment;
    handleSubmit : (payment: Payment) => Promise<void>;
    updateFormValidationIsValid : (isValid : boolean) => void;
    updatePaymentMethod: (method: string | null) => Promise<void>;
    updatePaymentFrequency: (frequency: string) => Promise<void>;
    updatePaymentDate: (paymentDate: string | null) => Promise<void>;
    updatePaymentProfile: (paymentProfileId: string | null) => Promise<void>;
    totalFull: number;
    totalMonthly: number;
    proposalTotal: string;
    discountPercent: number;
    installments: number;
    isPrior: boolean;
    proposalType: string;
    maxDate: string;
    contractPeriodStartMonth: string;
    paymentFrequency: string | null;
    paymentDate: string;
    paymentProfiles: Array<PaymentProfiles>;
    authNetTokenReady: boolean;
    paymentMethod: string;
    paymentChoices: string;
    isQuote: boolean;
    priorTo: string;
}

interface NameToValueMap
{
    [key: string]: any;
}

interface RadioOption
{
    label: string;
    value: string;
}

interface State {
    methodOptions: Array<Object>;
    showMethodOptions: boolean;
    showPaymentDate: boolean;
    showMonthlyDates : boolean;
    authNetPayment: boolean;
    currentMethod: string;
}

type Props = OwnProps;

class PaymentInformationForm extends Component<Props, State>
{
    public form : any;

    public state = {
        methodOptions: [
            {
                label: 'Credit Card',
                value: 'credit'
            },
            {
                label: 'Bank Account',
                value: 'bank'
            },
            {
                label: 'Send Timely Payments',
                value: 'timely'
            }
        ],
        showMethodOptions: false,
        showPaymentDate: false,
        showMonthlyDates: false,
        authNetPayment: false,
        currentMethod: ''
    }

    constructor(props : Props) {
        super(props);
        this.form = React.createRef();
    }

    async componentDidMount() {
        const {updateFormValidationIsValid} = this.props;
        const errors = await this.form.current.validateForm();
        updateFormValidationIsValid(Object.keys(errors).length === 0);

        if (this.form.current) {
            this.form.current.resetForm();
        }
    }

    public render() : ReactNode
    {
        const {
            totalFull,
            totalMonthly,
            proposalTotal,
            discountPercent,
            isPrior,
            installments,
            contractPeriodStartMonth,
            paymentDate,
            paymentMethod,
            paymentChoices
        } = this.props;

        const paymentMonthStart : string = isPrior ? contractPeriodStartMonth : moment().add(1, 'M').format('MM');

        let frequencyLabel = `Pay ${proposalTotal} in full`;
        const paymentChoicesArray : Array<string> = paymentChoices.split('\r');

        if (isPrior) {
            frequencyLabel = `Pay ${totalFull} in full - includes ${discountPercent}% discount`;
        }

        if (isPrior && (!discountPercent || discountPercent === 0)) {
            frequencyLabel = `Pay ${totalFull} in full`;
        }

        let paymentOptions = ['full', 'monthly', 'net30', 'special'];
        let methodOptions = ['timely', 'credit', 'bank', 'net30', 'special'];
        let radioOptions : Array<RadioOption> = [
            {
                label: frequencyLabel,
                value: 'full'
            },
            {
                label: `${installments} Monthly installments of ${totalMonthly}`,
                value: 'monthly'
            },
            {
                label: `Pay within 30 days of receiving invoice for completed service`,
                value: 'net30'
            },
            {
                label: `Pay with payment terms previously negotiated with Aquatic Control`,
                value: 'special'
            }
        ];

        const addPaymentOption = {
            label: 'Add New ' + (paymentMethod === 'credit' ? 'Credit Card' : 'Bank Account'),
            id: -1
        };
        let paymentProfileOptions : Array<RadioOption> = [...this.props.paymentProfiles, addPaymentOption].map(
            (profile) : RadioOption => {
                return {
                    label: profile.label,
                    value: profile.id.toString()
                }
        });

        if (!paymentChoicesArray.includes('Net 30')) {
            paymentOptions = paymentOptions.filter((option) => {
                return option !== 'net30';
            });
            radioOptions = radioOptions.filter((option) => {
                return option.value !== 'net30';
            });
        }

        if (!paymentChoicesArray.includes('Monthly')) {
            paymentOptions = paymentOptions.filter((option) => {
                return option !== 'monthly';
            });
            radioOptions = radioOptions.filter((option) => {
                return option.value !== 'monthly';
            });
        }

        if (!paymentChoicesArray.includes('Prepayment')) {
            paymentOptions = paymentOptions.filter((option) => {
                return option !== 'full';
            });
            radioOptions = radioOptions.filter((option) => {
                return option.value !== 'full';
            });
        }


        if (!paymentChoicesArray.includes('Special')) {
            paymentOptions = paymentOptions.filter((option) => {
                return option !== 'special';
            });
            radioOptions = radioOptions.filter((option) => {
                return option.value !== 'special';
            });
        }

        const authNetPaymentYup = this.state.authNetPayment ? yup.string().required() : yup.string();

        const schema = yup.object({
            frequency: yup.string().required().oneOf(
                paymentOptions, "You must select a frequency"
            ),
            method: yup.string().required().oneOf(
                methodOptions, 'You must select a payment method from the options above'
            ),
            startDate: yup.string().when('method', {
                is: (v: any) => {return v === 'bank' || v === 'credit'},
                then: yup.string().required('You must pick a Date for the charge')
            }),
            authNetPayment: authNetPaymentYup
        });

        const initialValues : NameToValueMap = {
            frequency: '',
            method: '',
            startDate: paymentDate,
            authNetPayment: '',
        };

        const contractMonthYear : number = moment() >= moment(moment().year()+'-'+paymentMonthStart+'-30', 'YYYY-MM-DD')
            ? moment().year()+1 : moment().year();

        return (
            <Formik
                onSubmit={this.handleSubmit}
                initialValues={initialValues}
                validationSchema={schema}
                ref={this.form}
            >
                {({
                      handleSubmit,
                      values,
                      errors,
                      setFieldValue
                  }) => (
                      <React.Fragment>
                        <form noValidate onSubmit={handleSubmit} id="mainFormContent">
                            <h3 className="text-primary">Payment Frequency</h3>
                            <Form.Row>
                                <div className="d-flex flex-row w-100">
                                    <FormGroup
                                        label="frequency"
                                        name="frequency"
                                        type="radio"
                                        value={values['frequency']}
                                        handleChange={this.handleChange}
                                        errors={errors['frequency']}
                                        radioOptions={radioOptions}
                                    />
                                    <FormGroup
                                        name="authNetPayment"
                                        type="hidden"
                                        value=""
                                        handleChange={this.handleChange}
                                    />
                                </div>
                            </Form.Row>
                            {this.state.showMethodOptions && (
                                <React.Fragment>
                                    <hr className="w-100"/>
                                    <h3 className="text-primary">Payment Method</h3>
                                    <Form.Row>
                                        <div className="d-flex flex-row w-100">
                                            <FormGroup
                                                label="method"
                                                name="method"
                                                type="radio"
                                                value={values['method']}
                                                handleChange={async (e: any) => {
                                                    setFieldValue('startDate', '');
                                                    setFieldValue('paymentProfile', '');
                                                    await this.handleChange(e);
                                                }}
                                                errors={errors['method']}
                                                radioOptions={this.state.methodOptions}
                                            />
                                        </div>
                                    </Form.Row>
                                </React.Fragment>
                            )}
                            {this.state.showPaymentDate && this.state.currentMethod !== 'timely' && (
                                <React.Fragment>
                                    <hr className="w-100"/>
                                    <h3 className="text-primary">Payment Date</h3>
                                    <Form.Row>
                                        <div className="d-flex flex-row w-100">
                                            <FormGroup
                                                name="startDate"
                                                type="date"
                                                value={values['startDate']}
                                                handleChange={this.handleChange}
                                                errors={errors['startDate']}
                                                min={new Date().toLocaleDateString('en-CA')}
                                                max={this.props.maxDate}
                                            />
                                        </div>
                                    </Form.Row>
                                </React.Fragment>
                            )}
                            {this.state.showMonthlyDates && (
                                <React.Fragment>
                                    <hr className="w-100"/>
                                    <h3 className="text-primary">Payment Withdraw Begin Date</h3><p>(Monthly installments will withdraw on the same day every month)</p>
                                    <Form.Row>
                                        <div className="d-flex flex-row w-100">
                                            <FormGroup
                                                name="startDate"
                                                type="radio"
                                                value={values['startDate']}
                                                handleChange={this.handleChange}
                                                errors={errors['startDate']}
                                                radioOptions={[
                                                    {
                                                        label: paymentMonthStart+'/01/'+contractMonthYear,
                                                        value: contractMonthYear+'-'+paymentMonthStart+'-01'
                                                    },
                                                    {
                                                        label: paymentMonthStart+'/10/'+contractMonthYear,
                                                        value: contractMonthYear+'-'+paymentMonthStart+'-10'
                                                    },
                                                    {
                                                        label: paymentMonthStart+'/20/'+contractMonthYear,
                                                        value: contractMonthYear+'-'+paymentMonthStart+'-20'
                                                    },
                                                    {
                                                        label: paymentMonthStart+'/30/'+contractMonthYear,
                                                        value: contractMonthYear+'-'+paymentMonthStart+'-30'
                                                    },
                                                ]}
                                            />
                                        </div>
                                    </Form.Row>
                                </React.Fragment>
                            )}
                            {this.props.authNetTokenReady && (
                                <React.Fragment>
                                    <h3 className="text-primary">Payment Method</h3>
                                    <Form.Row>
                                        <div className="d-flex flex-row w-100">
                                            <FormGroup
                                                label="paymentProfile"
                                                name="paymentProfile"
                                                type="radio"
                                                value={values['paymentProfile']}
                                                handleChange={this.handleChange}
                                                errors={errors['paymentProfile']}
                                                radioOptions={paymentProfileOptions}
                                            />
                                        </div>
                                    </Form.Row>
                                </React.Fragment>
                            )}
                        </form>
                      </React.Fragment>
                )}
            </Formik>
        );
    }

    private handleChange = async (e: React.FormEvent<HTMLFormElement> | undefined) => {
        const {updatePaymentMethod, updatePaymentFrequency, updatePaymentDate, isPrior, paymentFrequency, paymentDate, updatePaymentProfile, updateFormValidationIsValid} = this.props;
        await this.form.current.handleChange(e);

        // @ts-ignore
        const name = e?.target?.name;
        // @ts-ignore
        const value = e?.target?.value;

        if (name === 'method')
        {
            await updatePaymentDate(paymentDate ? paymentDate : null);
            if (value === 'timely') {
                this.setState({showMonthlyDates: false, authNetPayment: false, currentMethod: value});
            } else {
                //
                this.setState({authNetPayment: true, currentMethod: value});
                if (paymentFrequency === 'monthly') {
                    this.setState({showMonthlyDates: true});
                }
            }
            await updatePaymentMethod(value);
            await updatePaymentProfile(null);
        }

        if (name === 'frequency')
        {
            await updatePaymentDate(paymentDate ? paymentDate : null);
            await updatePaymentMethod(null);
            this.setState({authNetPayment: false});

            this.form.current.setFieldValue('method', null, true);

            if (value !== 'net30' && value !== 'special') {
                if ('full' === value) {
                    if (isPrior) {
                        this.setState({showPaymentDate: true, showMonthlyDates: false});
                    } else {
                        this.setState({showPaymentDate: false, showMonthlyDates: false});
                        await updatePaymentDate(moment().format('YYYY-MM-DD'));
                    }
                } else {
                    this.setState({showPaymentDate: false, showMonthlyDates: true});
                }
                this.setState({showMethodOptions: true});
            } else {
                this.form.current.setFieldValue('method', value, true);
                await updatePaymentMethod(value);
                this.setState({showMethodOptions: false, showMonthlyDates: false, authNetPayment: false, showPaymentDate: false});
            }
            this.updateMethodOptions(value);

            await updatePaymentFrequency(value);
        }

        if (name === 'startDate')
        {
            await updatePaymentDate(value);
        }

        if (name === 'paymentProfile')
        {
            await updatePaymentProfile(value);
        }

        const errors = await this.form.current.validateForm();
        updateFormValidationIsValid(Object.keys(errors).length === 0);
    };

    private handleSubmit = async (values : any) => {

        const {handleSubmit} = this.props;

        await handleSubmit({
            frequency: values.frequency,
            method: values.method,
            paymentDate: values.paymentDate,
            paymentProfileId: null
        });

        if (this.form.current) {
            this.form.current.resetForm();
        }
    };

    private updateMethodOptions = (frequency: string) => {
        if (frequency === 'full') {
            this.setState({methodOptions: [
                {
                    label: 'Credit Card',
                    value: 'credit'
                },
                {
                    label: 'Bank Account',
                    value: 'bank'
                },
                {
                    label: 'Send Timely Payments',
                    value: 'timely'
                }
            ]});
        }

        if (frequency === 'monthly') {
            this.setState({methodOptions: [
                {
                    label: 'Credit Card',
                    value: 'credit'
                },
                {
                    label: 'Bank Account',
                    value: 'bank'
                },
                {
                    label: 'Send Timely Payments',
                    value: 'timely'
                }
            ]});
        }

        if (frequency === 'net30') {
            this.setState({methodOptions: [
                {
                    label: 'Net 30',
                    value: 'net30'
                },
            ]});
        }

        if (frequency === 'special') {
            this.setState({methodOptions: [
                    {
                        label: 'Special',
                        value: 'special'
                    },
                ]});
        }
    }
}

export default (PaymentInformationForm);