import {connectRouter, routerMiddleware} from 'connected-react-router';
import {createBrowserHistory} from 'history';
import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import thunk from 'redux-thunk';
import {contactReducer} from "./contact/reducers";

export const history = createBrowserHistory();

const rootReducer = combineReducers({
    contact: contactReducer,
    router: connectRouter(history),
});

export type AppState = ReturnType<typeof rootReducer>;

export const store = createStore(
    rootReducer,
    compose(
        applyMiddleware(
            routerMiddleware(history),
            thunk
        )
    )
);