import React, {Component, ReactNode} from 'react';
import {Col, Container, Row} from "react-bootstrap";

interface OwnProps
{
}

type Props = OwnProps;

class Footer extends Component<Props>
{
    public render() : ReactNode
    {
        const year = new Date().getFullYear();
        return (
            <footer className="fixed-bottom p-4 bg-primary footer-menu">
                <Container>
                    <Row>
                        <Col lg={7} sm={6} md={7} className="d-flex align-content-start flex-row col-12">
                            <p>&copy; Copyright {year} Aquatic Control</p>
                        </Col>
                        <Col lg={4} sm={6} md={4} className="offset-md-1">
                            <ul className="d-flex flex-row justify-content-start justify-content-sm-end list-unstyled">
                                <li>
                                    <a
                                        className="text-light"
                                        href="https://www.aquaticcontrol.com/"
                                        target="_blank"
                                    >Home</a>
                                </li>
                                <li className="px-2">|</li>
                                <li>
                                    <a
                                        className="text-light"
                                        href="https://www.aquaticcontrol.com/about-us"
                                        target="_blank"
                                    >About Us</a>
                                </li>
                                <li className="px-2">|</li>
                                <li>
                                    <a
                                        className="text-light"
                                        href="tel:+1-800-753-5253"
                                        target="_blank"
                                    >800-753-LAKE</a>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </footer>
        );
    }
}

export default (Footer);