import React, {Component, ReactNode} from "react";
import {Formik} from 'formik';
import {Option, ProposalPdf} from "../../redux/contact/types";

interface OwnProps
{
    handleSubmit : (options: Array<Option>, proposalPdf: ProposalPdf) => Promise<void>;
    options: Array<Option>;
    proposalPdf: Pick<ProposalPdf, 'signature' | 'printedName' | 'proposal'>;
}

interface NameToValueMap
{
    [key: string]: any;
}

type Props = OwnProps;

class VerifyProposalForm extends Component<Props>
{
    public form : any;

    constructor(props : Props) {
        super(props);
        this.form = React.createRef();
    }

    public render() : ReactNode
    {
        const initialValues : NameToValueMap = {
        };

        return (
            <Formik
                onSubmit={this.handleSubmit}
                initialValues={initialValues}
                ref={this.form}
            >
                {({
                      handleSubmit,
                  }) => (
                    <form noValidate onSubmit={handleSubmit} id="mainFormContent" autoComplete="off">
                    </form>
                )}
            </Formik>
        );
    }

    private handleSubmit = async () => {
        const {handleSubmit, options, proposalPdf} = this.props;
        await handleSubmit(options, proposalPdf);
    };
}

export default (VerifyProposalForm);