import React, {Component, ReactNode} from 'react';
import {Document, Page, Text, Font, View, StyleSheet, Image} from '@react-pdf/renderer';
import poppinsRegularFont from '../../assets/fonts/Poppins/Poppins-Regular.ttf';
import poppinsBoldFont from '../../assets/fonts/Poppins/Poppins-Bold.ttf';
import poppinsSemiBoldFont from '../../assets/fonts/Poppins/Poppins-SemiBold.ttf';
import poppinsMediumFont from '../../assets/fonts/Poppins/Poppins-Medium.ttf';
import {Option, Proposal, ProposalPdf} from "../../redux/contact/types";
import logo from "../../images/aquatic-logo-pdf.png";
import lakeManagement from "../../images/lake-management-journey-icon.png";
import checkbox from "../../images/checkbox-checked-pdf.png";
import checkboxUnchecked from "../../images/checkbox-unchecked-pdf.png";
import moment from "moment";
import {formatPrice} from "../../utils/formatPrice";

Font.register( {
    family: 'Poppins-Regular',
    src: poppinsRegularFont,
    fontStyle: 'normal',
    fontWeight: 'normal',
});

Font.register( {
    family: 'Poppins-Bold',
    src: poppinsBoldFont,
    fontStyle: 'normal',
    fontWeight: 'normal',
});

Font.register( {
    family: 'Poppins-SemiBold',
    src: poppinsSemiBoldFont,
    fontStyle: 'normal',
    fontWeight: 'normal',
});

Font.register( {
    family: 'Poppins-Medium',
    src: poppinsMediumFont,
    fontStyle: 'normal',
    fontWeight: 'normal',
});

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Poppins-Regular',
        fontSize: 9,
        backgroundColor: '#f7f7f7',
    },
    header: {
        backgroundColor: '#fff',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        padding: '10px',
        fontSize: 9,
        color: '#828282',
        marginBottom: '10px',
    },
    headerColumn: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0 20px',
    },
    signature: {
        height: '40px',
        marginLeft: '10px',
        borderBottom: 0,
    },
    logo: {
        paddingTop: '2px',
        width: '75px',
        height: '37px',
    },
    lakeManagementLogo: {
        paddingTop: '2px',
        width: '100px'
    },
    textPrimary: {
        color: '#2c5196',
    },
    textPrimaryHeading: {
        color: '#2c5196',
        paddingBottom: '5px',
        fontSize: 11,
    },
    contentView: {
        margin: '0 10px 10px 10px',
        padding: '0 10px',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#fff',
        color: '#383838',
    },
    contentRow: {
        display: 'flex',
        flexDirection: 'row',
        padding: '10px 0',
    },
    contentColumn: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0 20px',
    },
    w25: {
        width: '25%',
    },
    w40: {
        width: '40%',
    },
    w20: {
        width: '20%',
    },
    w50: {
        width: '50%',
    },
    w75: {
        width: '75%',
    },
    pl0: {
        paddingLeft: '0 !important',
    },
    pr0: {
        paddingRight: '0 !important',
    },
    mt5: {
        marginTop: '5px',
    },
    mt10: {
        marginTop: '10px',
    },
    pt10: {
        paddingTop: '10px',
    },
    pb10: {
        paddingBottom: '10px',
    },
    borderDivider: {
        borderColor: '#dbdbdb',
        borderBottomWidth: 1,
    },
    borderDividerRight: {
        borderColor: '#d8d8d8',
        borderRightWidth: 1,
    },
    boldHeading: {
        fontFamily: 'Poppins-SemiBold',
        fontSize: 12,
    },
    optionTable: {
        display: 'flex',
        flexDirection: 'column',
        borderColor: '#d8d8d8',
        borderTopWidth: 1,
        borderLeftWidth: 1,
    },
    optionRow: {
        display: 'flex',
        flexDirection: 'row',
    },
    optionHeader: {
        backgroundColor: '#2c5196',
        color: '#fff',
        fontFamily: 'Poppins-SemiBold',
        fontSize: 9,
        borderBottom: '0 !important',
    },
    optionCell: {
        borderColor: '#d8d8d8',
        borderBottomWidth: 1,
        borderRightWidth: 1,
        padding: '5px 8px',
        fontSize: 8,
    },
    optionCellAdd: {
        width: '20%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    optionCellDesc: {
        width: '55%',
    },
    optionCellPrice: {
        width: '25%',
    },
    optionCellFull: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    checkbox: {
        width: '11px',
        height: '11px',
    },
    isPrior: {
        paddingTop: '5px',
        fontSize: 8,
    },
    textSemiBold: {
        fontFamily: 'Poppins-SemiBold',
    },
    specAndNotes: {
        fontSize: 8,
    },
    programCosts: {
        backgroundColor: '#f9f9f9',
        padding: '15px',
        display: 'flex',
        flexDirection: 'column',
        fontSize: 8,
    },
    programCostRow: {
        display: 'flex',
        flexDirection: 'row',
        paddingBottom: '5px',
    },
    textAlignRight: {
        textAlign: 'right',
    },
    flexCenter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    signatureRow: {
        display: 'flex',
        flexDirection: 'row',
        margin: '5px 0',
    },
    signatureColumnLeft: {
        width: '40%',
        color: '#2c5196',
        textAlign: 'right',
        bottom: 0,
    },
    signatureColumnRight: {
        width: '60%',
        paddingBottom: '3px',
        marginLeft: '10px',
    },
    signatureHeading: {
        marginBottom: 0,
        paddingBottom: 0,
        fontSize: 11,
    }
});

interface OwnProps
{
    proposalPdf: Pick<ProposalPdf, 'signature' | 'printedName'>;
    proposal: Proposal,
    options: Array<Option>;
}

type Props = OwnProps;

class ProposalPdfDocument extends Component<Props>
{
    public render() : ReactNode
    {
        const {proposalPdf, proposal, options} = this.props;
        const contact = proposal.proposalContact;
        const company = proposal.proposalCompany;
        const pdf = proposal.pdf;
        let pdfSignature = null;
        let pdfPrintedName = null;
        let date = moment().format('MM-DD-YYYY');
        let priorTo = moment(proposal.priorTo).format('MM-DD-YYYY');
        let orderDate = moment(proposal.orderDate).format('MM-DD-YYYY');
        const paymentChoicesArray : Array<string> = proposal.paymentChoices.split('\r');

        if (proposalPdf.signature) {
            pdfSignature = proposalPdf.signature;
            pdfPrintedName = proposalPdf.printedName;
        } else if (proposal.pdf.signature) {
            pdfSignature = proposal.pdf.signature;
            pdfPrintedName = proposal.pdf.printedName;
        }

        return (
            <Document title={pdf.sectionTitle}>
                <Page size="A4" style={styles.page}>
                    <View style={styles.header}>
                        <View style={[styles.contentColumn, styles.w50, styles.pl0]}>
                        <Image src={logo} style={styles.logo} />
                        </View>
                        <View style={[styles.headerColumn, styles.w50, styles.pl0]}>
                            <Text style={styles.textPrimary}>Company ID: {pdf.companyId}</Text>
                            <Text style={styles.textPrimary}>Company Name: {company.name}</Text>
                            <Text style={styles.textPrimary}>Date Created: {orderDate}</Text>
                            <Text style={styles.textPrimary}>Proposal #: {pdf.proposalNumber}</Text>
                        </View>
                    </View>
                    <View style={styles.contentView}>
                        <View style={[styles.contentRow, styles.borderDivider]}>
                            <View style={[styles.contentColumn, styles.pl0, styles.boldHeading, styles.w50]}>
                                <Text style={[styles.textPrimary]}>{pdf.sectionTitle}</Text>
                            </View>
                            <View style={[styles.contentColumn, styles.w50, styles.pl0]}>
                                <Text style={styles.textPrimary}>Contact Name: {contact.name}</Text>
                                <Text style={styles.textPrimary}>Property Name: {proposal.property.name}</Text>
                                <Text style={styles.textPrimary}>Company Address:&nbsp;
                                    {company.address.address}&nbsp;
                                    {company.address.city},&nbsp;
                                    {company.address.state}&nbsp;
                                    {company.address.postalCode}&nbsp;
                                </Text>
                            </View>
                        </View>
                        {pdf.programSpecifications !== '' && (
                        <View style={[styles.contentRow, styles.borderDivider]}>
                            <View style={[styles.contentColumn, styles.pl0, styles.pr0]}>
                                <Text style={styles.textPrimaryHeading}>Program Specifications:</Text>
                                <Text style={styles.specAndNotes}>{pdf.programSpecifications}</Text>
                            </View>
                        </View>
                        )}
                        {proposal.lineItemOptions !== '' && (
                            <View style={[styles.contentRow, styles.borderDivider]}>
                                <View style={[styles.contentColumn, styles.pl0, styles.pr0]}>
                                    <Text style={styles.specAndNotes}>{proposal.lineItemOptions}</Text>
                                </View>
                            </View>
                        )}
                        <View style={[styles.contentRow, styles.borderDivider]}>
                            <View style={[styles.contentColumn, styles.pl0, styles.w50]}>
                                <Text style={styles.textPrimaryHeading}>Optional Treatments / Services</Text>
                                <View style={styles.optionTable}>
                                    <View style={styles.optionRow}>
                                        <View style={[styles.optionCell, styles.optionHeader, styles.optionCellAdd]}>
                                            <Text>Add</Text>
                                        </View>
                                        <View style={[styles.optionCell, styles.optionHeader, styles.optionCellDesc]}>
                                            <Text>Option / Description</Text>
                                        </View>
                                        <View style={[styles.optionCell, styles.optionHeader, styles.optionCellPrice]}>
                                            <Text>Price</Text>
                                        </View>
                                    </View>
                                    {options.map( (option : Option) => (
                                        <View style={styles.optionRow} key={option.id}>
                                            <View style={[styles.optionCell, styles.optionCellAdd]}>
                                                {option.state ? (
                                                    <Image src={checkbox} style={styles.checkbox} />
                                                ) : (
                                                    <Image src={checkboxUnchecked} style={styles.checkbox} />
                                                )}
                                            </View>
                                            <View style={[styles.optionCell, styles.optionCellDesc]}>
                                                <Text>{option.description}</Text>
                                            </View>
                                            <View style={[styles.optionCell, styles.optionCellPrice]}>
                                                <Text>{formatPrice(option.priceUSD)}</Text>
                                            </View>
                                        </View>
                                    ))}
                                    {options.length < 1 && (
                                        <View style={[styles.optionCell, styles.optionCellFull]}>
                                            <Text>No Options Available</Text>
                                        </View>
                                    )}
                                </View>
                                {proposal.isPrior && paymentChoicesArray.includes('Prepayment') && (
                                    <Text style={styles.isPrior}>
                                        * The Proposal Total will be reduced by
                                        <Text style={[styles.textPrimary, styles.textSemiBold]}>
                                            &nbsp;{proposal.discountPercent}%
                                        </Text>&nbsp;
                                        if full payment is received on or before
                                        <Text style={[styles.textPrimary, styles.textSemiBold]}>&nbsp;{priorTo}</Text>.
                                        The Discounted Proposal Total Amount is
                                        <Text style={[styles.textPrimary, styles.textSemiBold]}>
                                            &nbsp;{proposal.totalFull}
                                        </Text>.
                                    </Text>
                                )}
                            </View>
                            <View style={[styles.contentColumn, styles.pl0, styles.w50, styles.pr0]}>
                                <Text style={styles.textPrimaryHeading}>Program Costs</Text>
                                <View style={styles.programCosts}>
                                    <View style={styles.programCostRow}>
                                        <Text style={[styles.w75]}>Program Price:</Text>
                                        <View style={[styles.w25]}>
                                            <Text style={[styles.textAlignRight]}>{formatPrice(proposal.subtotal, 2)}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.programCostRow}>
                                        <Text style={[styles.w75]}>Optional Treatments / Services:</Text>
                                        <View style={[styles.w25]}>
                                            <Text style={[styles.textAlignRight]}>{formatPrice(proposal.optionTotalUSD, 2)}</Text>
                                        </View>
                                    </View>
                                    <View style={[styles.programCostRow, styles.borderDivider]}>
                                        <Text style={[styles.w75]}>Tax:</Text>
                                        <View style={[styles.w25]}>
                                            <Text style={[styles.textAlignRight]}>${proposal.quoteTax}</Text>
                                        </View>
                                    </View>
                                    <View style={[styles.programCostRow, styles.textPrimary, styles.mt10]}>
                                        <Text style={[styles.w75]}>Proposal Total:</Text>
                                        <View style={[styles.w25]}>
                                            <Text style={[styles.textAlignRight]}>
                                                {formatPrice(proposal.proposalTotal, 2)}
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>
                        {pdf.notesAndPrecautions !== '' && (
                        <View style={[styles.contentRow, styles.borderDivider]}>
                            <View style={[styles.contentColumn, styles.pl0, styles.pr0]}>
                                <Text style={styles.textPrimaryHeading}>Notes &amp; Precautions:</Text>
                                <Text style={styles.specAndNotes}>{pdf.notesAndPrecautions}</Text>
                            </View>
                        </View>
                        )}
                        <View style={[styles.contentRow]}>
                            <View style={[styles.contentColumn, styles.pl0, styles.pr0]}>
                                <Text style={styles.textPrimaryHeading}>Terms:</Text>
                                {proposal.terms ? (
                                <Text style={styles.specAndNotes}>
                                    {proposal.terms}
                                </Text>
                                    ) : (
                                <Text style={styles.specAndNotes}>
                                    This contract is for the complete program as described in the above specifications
                                    and options sections, with material cost prorated over the entire contract period.
                                    Payment will be according to the payment option chosen on back. Overdue accounts
                                    shall accrue interest at an annual interest rate of 18%. Aquatic Control shall be
                                    entitled to collect its attorney fees incurred in the collection of any balance due
                                    here under. By signing below, you hereby agree and accept the above prices,
                                    specifications, conditions, and terms and authorize Aquatic Control to do the
                                    work as specified. Additionally, the undersigned warrants that the undersigned is
                                    authorized to sign and accept this proposal on behalf of
                                    <Text style={[
                                        styles.textPrimary,
                                        styles.textSemiBold
                                    ]}>&nbsp;{company.name}&nbsp;</Text>
                                    for software testing, and will indemnify and hold Aquatic Control harmless from
                                    third party claims for trespass, nuisance or any other challenge to Aquatic
                                    Control's authority to perform the work provided for herein.
                                </Text>
                                )}
                            </View>
                        </View>
                    </View>
                    <View style={[styles.contentView, styles.mt10, styles.pt10, styles.pb10]} break>
                        <View style={styles.flexCenter}>
                            <Text style={[styles.boldHeading, styles.textPrimary]}>Acceptance Of Proposal</Text>
                            <Text>
                                The above prices specifications and conditions are satisfactory and are hereby accepted.
                                Aquatic Control is authorized to do the work as specified.
                            </Text>
                        </View>
                        <View style={[styles.contentRow]}>
                            <View style={[styles.contentColumn, styles.pl0, styles.w20]}>
                                <Image src={lakeManagement} style={styles.lakeManagementLogo} />
                            </View>
                            <View style={[styles.contentColumn, styles.pl0, styles.w40]}>
                                <View style={styles.signatureRow}>
                                    <Text style={styles.signatureColumnLeft}> &nbsp;</Text>
                                    <Text style={[
                                        styles.signatureColumnRight,
                                        styles.textPrimary,
                                        styles.signatureHeading
                                    ]}>Vendor</Text>
                                </View>
                                {(pdf.preparedByName && pdf.preparedByName !== '') ? (
                                    <View style={[styles.signatureRow]}>
                                        <Text style={styles.signatureColumnLeft}>Prepared By:</Text>
                                        <Text style={[styles.signatureColumnRight, styles.borderDivider]}>
                                            {pdf.preparedByName}
                                        </Text>
                                    </View>
                                ) : (
                                    <View style={[styles.signatureRow]}>
                                        <Text style={styles.signatureColumnLeft}>Prepared By:</Text>
                                        <Text style={[styles.signatureColumnRight, styles.borderDivider]}>
                                            &nbsp;
                                        </Text>
                                    </View>
                                )}
                                {pdf.authorizedSignature ? (
                                    <View style={[styles.signatureRow]}>
                                        <Text style={styles.signatureColumnLeft}>Authorized Signature:</Text>
                                        <Image
                                            src={pdf.authorizedSignature}
                                            style={[styles.signature]}
                                        />
                                    </View>
                                ) : (
                                    <View style={[styles.signatureRow]}>
                                        <Text style={styles.signatureColumnLeft}>Authorized Signature:</Text>
                                        <Text style={[styles.signatureColumnRight, styles.borderDivider]}>
                                            &nbsp;
                                        </Text>
                                    </View>
                                )}
                                {(pdf.authorizedSignatureName && pdf.authorizedSignatureName !== '') ? (
                                    <View style={[styles.signatureRow]}>
                                        <Text style={styles.signatureColumnLeft}>Printed:</Text>
                                        <Text style={[styles.signatureColumnRight, styles.borderDivider]}>
                                            {pdf.authorizedSignatureName}
                                        </Text>
                                    </View>
                                ) : (
                                    <View style={[styles.signatureRow]}>
                                        <Text style={styles.signatureColumnLeft}>Printed:</Text>
                                        <Text style={[styles.signatureColumnRight, styles.borderDivider]}>
                                            &nbsp;
                                        </Text>
                                    </View>
                                )}
                                <View style={styles.signatureRow}>
                                    <Text style={styles.signatureColumnLeft}>Dated:</Text>
                                    <Text style={styles.signatureColumnRight}>{orderDate}</Text>
                                </View>
                            </View>
                            <View style={[styles.contentColumn, styles.pl0, styles.w40]}>
                                <View style={styles.signatureRow}>
                                    <Text style={styles.signatureColumnLeft}> &nbsp;</Text>
                                    <Text style={[
                                        styles.signatureColumnRight,
                                        styles.textPrimary,
                                        styles.signatureHeading
                                    ]}>Client</Text>
                                </View>
                                {pdfSignature ? (
                                    <View style={styles.signatureRow}>
                                        <Text style={styles.signatureColumnLeft}>Client Signature:</Text>
                                        <Image src={pdfSignature} style={styles.signature} />
                                    </View>
                                ) : (
                                    <View style={styles.signatureRow}>
                                        <Text style={styles.signatureColumnLeft}>Client Signature:</Text>
                                        <Text style={[styles.signatureColumnRight, styles.borderDivider]}>
                                            &nbsp;
                                        </Text>
                                    </View>
                                )}
                                {pdfSignature ? (
                                    <View style={styles.signatureRow}>
                                        <Text style={styles.signatureColumnLeft}>Printed Name:</Text>
                                        <Text style={[
                                            styles.signatureColumnRight,
                                            styles.borderDivider
                                        ]}>{pdfPrintedName}</Text>
                                    </View>
                                ) : (
                                    <View style={styles.signatureRow}>
                                        <Text style={styles.signatureColumnLeft}>Printed Name:</Text>
                                        <Text style={[styles.signatureColumnRight, styles.borderDivider]}>
                                            &nbsp;
                                        </Text>
                                    </View>
                                )}
                                <View style={styles.signatureRow}>
                                    <Text style={styles.signatureColumnLeft}>Dated:</Text>
                                    <Text style={styles.signatureColumnRight}>{date}</Text>
                                </View>
                            </View>
                        </View>
                        <View style={[styles.flexCenter]}>
                            <Text style={[styles.textPrimary, styles.boldHeading]}>Send Timely Payments To: Aquatic Control, Inc. - PO Box 100, Seymour, IN  47274</Text>
                            <Text style={[styles.textPrimary, styles.boldHeading]}>Questions? Call Toll Free 800-753-5253</Text>
                        </View>
                    </View>
                </Page>
            </Document>
        );
    }
}

export default ProposalPdfDocument;