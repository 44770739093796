import React, {Component, ReactNode} from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Logo from '../branding/Logo';

interface OwnProps {
}

type Props = OwnProps;

class NavBase extends Component<Props> {
    public render(): ReactNode {

        return (
            <Navbar expand={true} className="bg-white shadow-sm">
                <Container className="d-flex align-items-start">
                    <Navbar.Brand href="https://aquaticcontrol.com">
                        <Logo/>
                    </Navbar.Brand>
                    {this.props.children}
                </Container>
            </Navbar>
        );
    }
}

export default (NavBase);
