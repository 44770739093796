import React, {Component, ReactNode} from 'react';
import {Spinner} from "react-bootstrap";

interface OwnProps {
    value : string;
    loading : boolean;
    text? : string;
}

type Props = OwnProps;

class ProposalTotal extends Component<Props> {
    public render(): ReactNode {

        const { value, loading, text } = this.props;

        return (
            <p>
                <span className={`proposalTotal ${text}`}>
                    {!loading ? (
                        <strong>{value}</strong>
                    ) : (
                        <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    )}
                </span>
            </p>
        );
    }
}

export default (ProposalTotal);