export interface Option {
    id: string;
    description: string;
    price: number;
    state: boolean;
    priceUSD: string;
}

export interface Coordinates {
    lat: number;
    lng: number;
}

export interface AdditionalLake
{
    name: string;
    coordinates: Coordinates;
}

export interface Lake
{
    name: string;
    coordinates: Coordinates;
}

export interface Property
{
    name: string;
    notes: string | null;
}

export interface Address
{
    address: string;
    city: string;
    state: string;
    postalCode: string;
}

export interface ProposalContact
{
    name: string;
    phone: string;
    email: string;
    homePhone?: string | null;
    officePhone?: string | null;
    cellPhone?: string | null;
    address: Address;
}

export interface ProposalCompany
{
    name: string;
    phone: string;
    address: Address;
}

export interface ProposalPdf
{
    proposal: string | null | undefined;
    signature: string | null;
    printedName: string | null;
    sectionTitle?: string;
    programSpecifications?: string;
    notesAndPrecautions?: string;
    authorizedSignature?: string;
    authorizedSignatureName?: string;
    preparedByName?: string;
    officePhone?: string;
    officeFax?: string;
    companyId?: string;
    proposalNumber?: string;
}

export interface Payment
{
    paymentDate: string | null;
    frequency: string | null;
    method: string | null;
    paymentProfileId: string | null;
    forFutureUse?: boolean;
}

export interface QuoteProduct
{
    productId: string;
    description: string;
    price: string;
    quantity: string;
    uom: string;
    extendedPrice: string;
}

export const RETAIL = 'Retail';

export interface Proposal
{
    id : string;
    proposalState: number;
    totalFull: number;
    totalMonthly: number;
    title: string;
    sectionTitle: string;
    validThrough: string;
    proposalCompany: ProposalCompany;
    proposalContact: ProposalContact;
    property: Property;
    lakes: Array<Lake>;
    additionalLakes: Array<AdditionalLake>;
    options: Array<Option>;
    optionTotalUSD: string;
    proposalTotal: string;
    subtotal: string;
    shipping: Shipping;
    taxRate: string;
    nameMatch: boolean;
    pdf: ProposalPdf;
    authHostedToken: string | null;
    paymentProfiles: Array<PaymentProfiles>
    authPaymentProfileId: number | null;
    payment: Payment;
    discountPercent: number;
    priorTo: string;
    installments: number;
    isPrior: boolean;
    type: string;
    prepayExpires: string;
    contractPeriodStartMonth: string;
    terms: string;
    lineItemOptions: string;
    paymentChoices: string;
    isQuote: boolean;
    products: Array<QuoteProduct>;
    shippingCost: string;
    quoteTax: string;
    quoteSubtotal: string;
    orderDate: string;
    customerType: typeof RETAIL | 'Wholesale' | 'Distributor' | 'Special' | 'Dealer' | '';
}

export interface Shipping
{
    address : Address
    carrier : string;
    name : string;
    poNumber : string;
    specialInstructions : Array<string>;
}

export interface PaymentProfiles
{
    id : number;
    label : string;
}

export interface Contact
{
    id : string;
    token: string;
    proposals: Array<Proposal>;
}

export interface ContactState
{
    contact : Contact | null;
}

export const GET_CONTACT_PROPOSALS = 'CONTACT_GET_CONTACT_PROPOSALS';
export const UPDATE_PROPOSAL_COMPANY = 'CONTACT_UPDATE_PROPOSAL_COMPANY';
export const UPDATE_PROPOSAL_CONTACT = 'CONTACT_UPDATE_PROPOSAL_CONTACT';
export const UPDATE_PROPOSAL_PROPERTY = 'CONTACT_UPDATE_PROPOSAL_PROPERTY';
export const UPDATE_PROPOSAL_LAKES = 'CONTACT_UPDATE_PROPOSAL_LAKES';
export const UPDATE_PROPOSAL_OPTIONS = 'CONTACT_UPDATE_PROPOSAL_OPTIONS';
export const UPDATE_PROPOSAL_STATE = 'CONTACT_UPDATE_PROPOSAL_STATE';
export const ACCEPT_PROPOSAL = 'CONTACT_ACCEPT_PROPOSAL';
export const SET_AUTH_HOSTED_TOKEN = 'CONTACT_SET_AUTH_HOSTED_TOKEN';
export const SUBMIT_PAYMENT_INFORMATION = 'CONTACT_SUBMIT_PAYMENT_INFORMATION';

interface GetContactProposalsAction
{
    type : typeof GET_CONTACT_PROPOSALS;
    payload : {
        contact : Contact;
    };
}

interface UpdateProposalContact
{
    type : typeof UPDATE_PROPOSAL_CONTACT;
    payload : {
        proposalState : number;
        proposalContact: ProposalContact;
        proposalId: string;
        proposalCompany?: ProposalContact | null;
    };
}

interface UpdateProposalLakes
{
    type : typeof UPDATE_PROPOSAL_LAKES;
    payload : {
        proposalState : number;
        additionalLakes: Array<AdditionalLake>;
        proposalId: string;
    };
}

interface UpdateProposalState
{
    type : typeof UPDATE_PROPOSAL_STATE;
    payload : {
        proposalState : number;
        proposalId: string;
    };
}

interface UpdateProposalCompany
{
    type : typeof UPDATE_PROPOSAL_COMPANY;
    payload : {
        proposalState : number;
        proposalCompany: ProposalCompany;
        proposalId: string;
    };
}

interface UpdateProposalProperty
{
    type : typeof UPDATE_PROPOSAL_PROPERTY;
    payload : {
        proposalState : number;
        property: Property;
        proposalId: string;
    };
}

interface UpdateProposalOptions
{
    type : typeof UPDATE_PROPOSAL_OPTIONS;
    payload : {
        proposalId: string;
        totalFull: number;
        totalMonthly: number;
        optionTotalUSD: string;
        proposalTotal: string;
        subtotal: string;
        taxRate: string;
        options: Array<Option>;
    };
}

interface AcceptProposal
{
    type : typeof ACCEPT_PROPOSAL;
    payload : {
        options: Array<Option>;
        proposal: string;
        proposalState : number;
        proposalId: string;
    };
}

interface SubmitPaymentInformation
{
    type : typeof SUBMIT_PAYMENT_INFORMATION;
    payload : {
        payment: Payment;
        proposalState : number;
        proposalId: string;
    };
}

interface SetAuthHostedToken
{
    type : typeof SET_AUTH_HOSTED_TOKEN;
    payload : {
        paymentProfiles : [{
            id : number;
            label : string;
        }];
        authHostedToken : string;
        proposalId: string;
    };
}

export type ContactActionTypes =
    GetContactProposalsAction
    | UpdateProposalCompany
    | UpdateProposalContact
    | UpdateProposalProperty
    | UpdateProposalState
    | UpdateProposalLakes
    | UpdateProposalOptions
    | AcceptProposal
    | SetAuthHostedToken
    | SubmitPaymentInformation
;