import React, {Component, ReactNode} from "react";
import ModalBase from "./ModalBase";
import BottomScrollListener from 'react-bottom-scroll-listener';

interface OwnProps
{
    show : boolean;
    handleClose : () => void;
    handleConfirm : () => void;
    company: string;
    terms : string;
}

interface State {
    viewed: boolean;
    termsError: boolean;
}

type Props = OwnProps;

class TermsModal extends Component<Props, State>
{
    public readonly state = {
        viewed: false,
        termsError: false
    };

    public render() : ReactNode
    {
        const {show, handleClose, company, terms} = this.props;

        return (
            <ModalBase
                show={show}
                handleClose={handleClose}
                handleConfirm={this.handleConfirm}
                title="Terms & Conditions"
                confirmText="I Accept"
                refForm="termsAndConditions"
                size="md"
                btnClass="smallModalBtn"
            >
                <BottomScrollListener onBottom={this.scrollBot}>
                    {scrollRef => (
                        // @ts-ignore
                        <div className="scroll-box" ref={scrollRef}>
                            {terms ? terms : (
                                <>
                                    <p>This contract is for the complete program as described in the above specifications and options
                                        sections, with material cost prorated over the entire contract period. Payment will be according
                                        to the payment option chosen on back. Overdue accounts shall accrue interest at an annual interest
                                        rate of 18%. Aquatic Control shall be entitled to collect its attorney fees incurred in the
                                        collection of any balance due here under.</p>
                                    <p>By signing below, you hereby agree and accept the above
                                        prices, specifications, conditions, and terms and authorize Aquatic Control to do the work as
                                        specified. Additionally, the undersigned warrants that the undersigned is authorized to sign and
                                        accept this proposal on behalf of {company} for software testing, and will indemnify and hold
                                        Aquatic Control harmless from third party claims for trespass, nuisance or any other challenge
                                        to Aquatic Control's authority to perform the work provided for herein.</p>
                                </>
                            )}
                        </div>
                    )}
                </BottomScrollListener>
                {this.state.termsError && (
                    <p className="text-danger w-100 mt-2 pb-0 mb-0">
                        You must scroll the Terms & Conditions before clicking "I Accept".
                    </p>
                )}
            </ModalBase>
        );
    }

    private scrollBot = (): void => {
        this.setState({viewed: true});
        this.setState({termsError: false});
    };

    private handleConfirm = (): void => {
        if (!this.state.viewed) {
            this.setState({termsError: true});
            return;
        }

        this.setState({termsError: false});
        const {handleConfirm} = this.props;
        handleConfirm();
    };
}

export default (TermsModal);