import React, {Component, ReactNode} from 'react';
import {Formik} from 'formik';
import * as yup from 'yup';
import Form from "react-bootstrap/Form";
import FormGroup from './FormGroup';
import {Property} from "../../redux/contact/types";

interface OwnProps
{
    property: Property;
    handleSubmit : (property : Property) => Promise<void>;
    updateFormValidationIsValid : (isValid : boolean) => void;
}

interface NameToValueMap
{
    [key: string]: any;
}

type Props = OwnProps;

class VerifyPropertyInformationForm extends Component<Props>
{
    public form : any;

    constructor(props : Props) {
        super(props);
        this.form = React.createRef();
    }

    async componentDidMount() {
        const {updateFormValidationIsValid} = this.props;
        const errors = await this.form.current.validateForm();
        updateFormValidationIsValid(Object.keys(errors).length === 0);
    }

    public render() : ReactNode
    {
        const {property} = this.props;

        const schema = yup.object({
            name: yup.string().required(),
            notes: yup.string().notRequired(),

        });

        const initialValues : NameToValueMap = {
            name: property.name,
            notes: property.notes,
        };

        return (
            <Formik
                onSubmit={this.handleSubmit}
                initialValues={initialValues}
                validationSchema={schema}
                ref={this.form}
            >
                {({
                      handleSubmit,
                      values,
                      errors
                  }) => (
                      <React.Fragment>
                        <form noValidate onSubmit={handleSubmit} id="mainFormContent">
                            <Form.Row>
                                <div className="d-flex flex-row w-100">
                                    <FormGroup
                                        label="Property name"
                                        name="name"
                                        type="text"
                                        value={values['name']}
                                        handleChange={this.handleChange}
                                        errors={errors['name']}
                                    />
                                </div>
                            </Form.Row>
                            <div className="d-flex flex-column w-100">
                                <hr className="w-100" />
                                <p>Use the property notes text area below to include any special instructions
                                    for our on-site applicators. </p>
                            </div>
                            <Form.Row>
                                <div className="d-flex flex-row w-100">
                                    <FormGroup
                                        label="Property notes"
                                        name="notes"
                                        type="textarea"
                                        value={values['notes']}
                                        handleChange={this.handleChange}
                                        errors={errors['notes']}
                                    />
                                </div>
                            </Form.Row>
                        </form>
                      </React.Fragment>
                )}
            </Formik>
        );
    }

    private handleChange = async (e: React.FormEvent<HTMLFormElement> | undefined) => {
        await this.form.current.handleChange(e);
        const {updateFormValidationIsValid} = this.props;
        const errors = await this.form.current.validateForm();
        updateFormValidationIsValid(Object.keys(errors).length === 0);
    };

    private handleSubmit = async (values : any) => {

        const {handleSubmit} = this.props;

        await handleSubmit({
            name: values.name,
            notes: values.notes
        });

        if (this.form.current) {
            this.form.current.resetForm();
        }
    };
}

export default (VerifyPropertyInformationForm);