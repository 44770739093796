import React, {Component, ReactNode} from "react";
import {Formik} from 'formik';
import * as yup from 'yup';
import FormGroup from './FormGroup';
import {Coordinates} from "../../redux/contact/types";
import {Form} from "react-bootstrap";

interface OwnProps
{
    handleSubmit : (lakeName : string, coordinates: Coordinates) => Promise<void>;
    coordinates: Coordinates;
}

interface Field
{
    label : string;
    name : string;
    type : string;
    disabled : boolean;
}

interface NameToValueMap
{
    [key: string]: any;
}

type Props = OwnProps;

class AddAdditionalLakeForm extends Component<Props>
{
    public form : any;

    constructor(props : Props) {
        super(props);
        this.form = React.createRef();
    }

    public render() : ReactNode
    {
        const {coordinates} = this.props;

        const schema = yup.object({
            lakeName: yup.string().required(),
            coordinates: yup.string().required()
        });

        const additionalLakeFieldset : Array<Field> = [
            {label : 'Lake Name', name : 'lakeName', type : 'text', disabled: false},
            {label : 'Coordinates', name : 'coordinates', type : 'text', disabled: true},
        ];

        const initialValues : NameToValueMap = {
            lakeName: '',
            coordinates: coordinates.lat.toString().substring(0, 8)
                + ' N, ' + coordinates.lng.toString().substring(0, 8)
                + ' W'
        };

        return (
            <Formik
                onSubmit={this.handleSubmit}
                initialValues={initialValues}
                validationSchema={schema}
                ref={this.form}
            >
                {({
                      handleSubmit,
                      values,
                      errors
                  }) => (
                    <form noValidate onSubmit={handleSubmit} id="addAdditionalLakeForm" autoComplete="off">
                        <Form.Row>
                            <div className="d-flex w-100 flex-column">
                                {additionalLakeFieldset.map( (field : Field) => (
                                    <FormGroup
                                        key={field.name}
                                        label={field.label}
                                        name={field.name}
                                        type={field.type}
                                        value={values[field.name]}
                                        handleChange={this.handleChange}
                                        errors={errors[field.name]}
                                        md={8}
                                        disabled={field.disabled}
                                    />
                                ))}
                            </div>
                        </Form.Row>
                    </form>
                )}
            </Formik>
        );
    }

    private handleChange = async (e: React.FormEvent<HTMLFormElement> | undefined) => {
        await this.form.current.handleChange(e);
        await this.form.current.validateForm();
    };

    private handleSubmit = async (values : any) => {
        const {handleSubmit, coordinates} = this.props;
        await handleSubmit(values.lakeName, coordinates);
    };
}

export default (AddAdditionalLakeForm);