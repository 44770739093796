import React, {Component, ReactNode} from 'react';
import {Col} from "react-bootstrap";
import greenCheck from '../../images/green-check.svg';
import pdfIcon from "../../images/pdf-icon.svg";
import {saveAs} from "file-saver";

interface ProgressBarState {
    name: any;
    proposalState: number;
    number: number;
}

interface OwnProps {
    proposalState: number;
    handleClose: (proposalId: string | null) => void;
    jumpToEditInfo: () => void;
    jumpBack: (newStep : number) => void;
    proposal: string;
    isQuote: boolean;
}

type Props = OwnProps;

class ProgressBar extends Component<Props> {
    public render(): ReactNode {
        const {proposalState, handleClose, isQuote, jumpToEditInfo, jumpBack} = this.props;

        let currentProposalState = proposalState;
        if (currentProposalState === undefined) {
            currentProposalState = 100;
        }

        //const canMoveBack = currentProposalState < 800;

        let progressBarStates: ProgressBarState[] = [
            {name: 'Company', proposalState: 100, number: 1},
            {name: 'Contact', proposalState: 200, number: 2},
            {name: 'Property', proposalState: 300, number: 3},
            {name: 'Lake', proposalState: 400, number: 4},
            {name: 'Lake', proposalState: 500, number: 4},
            {name: 'Proposal', proposalState: 600, number: 5},
            {name: 'Proposal', proposalState: 700, number: 5},
            {name: 'Payment', proposalState: 800, number: 6}
        ];

        if (currentProposalState === 500) {
            progressBarStates = progressBarStates.filter((object: ProgressBarState) => {
               return object.proposalState !== 400 && object.proposalState !== 700;
            });
        } else if (currentProposalState === 700) {
            progressBarStates = progressBarStates.filter((object: ProgressBarState) => {
                return object.proposalState !== 500 && object.proposalState !== 600;
            });
        } else {
            progressBarStates = progressBarStates.filter((object: ProgressBarState) => {
                return object.proposalState !== 500 && object.proposalState !== 700;
            });
        }

        if (currentProposalState > 700) {
            progressBarStates = progressBarStates.map(
                (progressBarState: ProgressBarState) => progressBarState.proposalState === 600
                    ? {
                        ...progressBarState,
                        name: <div className="d-flex flex-row pt-1">
                            <span>{isQuote ? 'Quote' : 'Proposal'}</span>
                            <div className="btn-link cursor-pointer" onClick={() => {
                                const {proposal} = this.props;
                                saveAs(proposal);
                            }}>
                                <img src={pdfIcon} alt="Proposal PDF" className="ml-2"/>
                            </div>
                        </div>
                    } : progressBarState
            );
        }

        let currentProgressBg = currentProposalState === 500 || currentProposalState === 700
            ? 'bg-danger' : 'bg-primary';

        let currentTextColor = currentProposalState === 500 || currentProposalState === 700
            ? 'text-danger' : 'text-primary';

        return (
            <Col lg={3} xl={2} className="pl-0 py-2 pr-0 pr-lg-4 d-flex align-items-center flex-column">
                <div className="rounded shadow-sm bg-white d-flex flex-column w-100 p-3">
                    {progressBarStates.map( (progressBarState: ProgressBarState) => {
                        const pastChildren = progressBarState.proposalState < 800 ?
                            <span className="cursor-pointer" onClick={() => jumpBack(progressBarState.proposalState)}>
                                <img src={greenCheck} alt="Step Complete" className="progress-step-custom" />
                                <span className="pl-2 text-success">{progressBarState.name === 'Proposal' && isQuote ? 'Quote' : progressBarState.name}</span>
                            </span> :
                            <React.Fragment>
                                <img src={greenCheck} alt="Step Complete" className="progress-step-custom" />
                                <span className="pl-2 text-success">{progressBarState.name === 'Proposal' && isQuote ? 'Quote' : progressBarState.name}</span>
                            </React.Fragment>;

                        return (
                        <React.Fragment key={progressBarState.name === 'Proposal' && isQuote ? 'Quote' : progressBarState.name}>
                        {currentProposalState > progressBarState.proposalState && (
                            <div className="d-flex flex-row align-items-center">
                                {pastChildren}
                            </div>
                        )}
                        {currentProposalState === progressBarState.proposalState && (
                            <div className="d-flex flex-row align-items-center">
                                <span
                                    className={`${currentProgressBg} rounded-circle progress-step-custom d-flex 
                                    justify-content-center align-items-center text-white progress-text`}
                                >{progressBarState.number}</span>
                                <span className={`pl-2 ${currentTextColor}`}>{progressBarState.name === 'Proposal' && isQuote ? 'Quote' : progressBarState.name}</span>
                            </div>
                        )}
                        {currentProposalState < progressBarState.proposalState && (
                            <div className="d-flex flex-row align-items-center">
                                <span
                                className="bg-white rounded-circle progress-step-custom d-flex justify-content-center
                                align-items-center progress-text border">{progressBarState.number}</span>
                                <span className="pl-2 text-secondary">{progressBarState.name === 'Proposal' && isQuote ? 'Quote' : progressBarState.name}</span>
                            </div>
                        )}
                        {progressBarState.proposalState < 800 && (
                            <div className="border-left p-10 ml-3"> &nbsp; </div>
                        )}
                        </React.Fragment>
                    )})}
                </div>
                {currentProposalState===600 && (<button
                    className="btn btn-outline-secondary w-100 mt-4"
                    onClick={() => jumpToEditInfo()}
                >Edit Information</button>)}
                <button
                    className="btn btn-outline-secondary w-100 my-4"
                    onClick={() => handleClose(null)}
                >Close {isQuote ? 'Quote': 'Proposal'}</button>
            </Col>
        );
    }
}

export default (ProgressBar);